import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { doc, getDoc } from "firebase/firestore";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colors } from "../lib/theme";
import { db } from "../../shared/firebase";
import { useAuth } from "../../shared/useAuth";
import AlbumCover from "../Album/AlbumCover";
import RefreshLink from "../../shared/RefreshLink";
import TrackItem from "../Album/TrackItem";
import useAudioPlayer from "../../shared/useAudioPlayer";

const reactRootDataset = document.getElementById("react-root").dataset;

const Track = () => {
  const [track, setTrack] = useState(null);
  const [album, setAlbum] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user, loading: userLoading } = useAuth();
  const albumId = reactRootDataset.albumId;
  const trackId = reactRootDataset.trackId;
  const navigate = useNavigate();
  const [sharedWith, setSharedWith] = useState([]);

  const {
    isPlaying,
    playingTrackId,
    currentTime,
    duration,
    handlePause,
    handlePlayPause,
    handleSeek,
    selectedTrackId,
    playingSegmentIndex,
    handleDeleteSegment,
    startRecording,
    stopRecording,
    isRecording,
    recordingTrackId,
    remainingTime,
  } = useAudioPlayer(albumId, album?.userId, user, sharedWith, null, setTrack);

  useEffect(() => {
    const fetchTrackAndAlbum = async () => {
      console.log("Fetching track and album");
      console.log("albumId:", albumId, "trackId:", trackId);
      console.log("user:", user);
      console.log("userLoading:", userLoading);

      if (userLoading) {
        console.log("User loading, skipping fetch");
        return;
      }

      setLoading(true);
      setError(null);

      if (!albumId || !trackId) {
        console.log("No album ID or track ID provided");
        setError("No album ID or track ID provided");
        setLoading(false);
        return;
      }

      try {
        const albumDoc = await getDoc(doc(db, "albums", albumId));

        if (albumDoc.exists()) {
          const albumData = { id: albumDoc.id, ...albumDoc.data() };
          console.log("Album data:", albumData);
          setAlbum(albumData);

          // Set sharedWith here
          setSharedWith(albumData.sharedWith || []);

          const foundTrack = albumData.tracks.find((t) => t.id === trackId);

          if (foundTrack) {
            console.log("Found track:", foundTrack);
            setTrack(foundTrack);

            console.log("albumData.privacyLevel:", albumData.privacyLevel);
            console.log("albumData.userId:", albumData.userId);
            console.log("user.uid:", user?.uid);
            console.log("albumData.sharedWith:", albumData.sharedWith);

            const isPublic = albumData.privacyLevel === 2;

            if (!isPublic && !user) {
              console.log(
                "Non-public track, user not logged in. Redirecting to login."
              );
              navigate(
                `/login/?redirect_to=/album/${albumId}/track/${trackId}`
              );
              return;
            }

            if (!isPublic) {
              const isOwner = user && albumData.userId === user.uid;
              const isShared =
                user &&
                albumData.sharedWith &&
                albumData.sharedWith.includes(user.uid);

              if (!isOwner && !isShared) {
                console.log(
                  "User does not have access to this non-public track"
                );
                navigate("/login");
                return;
              }
            }
          } else {
            console.log("Track not found in the album");
            setError("Track not found in the album");
          }
        } else {
          console.log("Album not found");
          setError("Album not found");
        }
      } catch (error) {
        console.error("Error fetching album and track:", error);
        setError("An error occurred while fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchTrackAndAlbum();
  }, [albumId, trackId, user, userLoading, navigate]);

  const handleStopRecording = async () => {
    await stopRecording();
  };

  console.log("Rendering Track component");
  console.log(
    "loading:",
    loading,
    "error:",
    error,
    "track:",
    track,
    "album:",
    album
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress sx={{ color: colors.gold }} />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <>
      <Helmet>
        <title>{track?.title} - Golden Record</title>
        <meta property="og:image" content={album?.imageUrl} />
        <meta property="og:title" content={track?.title} />
        <meta
          property="og:description"
          content={`Track from album: ${album?.title}`}
        />
        <meta
          property="og:url"
          content={`https://app.goldenrecord.app/track/${trackId}`}
        />
        <meta name="apple-itunes-app" content="app-id=6501951254" />
      </Helmet>
      <Typography variant="h3" sx={{ color: colors.gold, mb: 1 }}>
        {track?.title}
      </Typography>
      <Typography variant="h6" sx={{ color: colors.white, mb: 4 }}>
        From the album:{" "}
        <RefreshLink to={`/album/${album?.id}`} sx={{ color: colors.gold }}>
          {album?.title}
        </RefreshLink>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 4,
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "400px" } }}>
          <AlbumCover
            imageUrl={album?.imageUrl}
            handleImageClick={() => {}}
            handleImageUpload={() => {}}
            user={null}
            userId={null}
            sharedWith={[]}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {track && album && (
            <TrackItem
              openAudioSegmentsOnload={true}
              track={track}
              isPlaying={isPlaying}
              playingTrackId={playingTrackId}
              handlePlayPause={handlePlayPause}
              currentTime={currentTime}
              duration={duration}
              handleSeek={handleSeek}
              selectedTrackId={selectedTrackId}
              user={user}
              userId={album?.userId}
              sharedWith={album?.sharedWith || []}
              isRecording={isRecording}
              recordingTrackId={recordingTrackId}
              stopRecording={stopRecording}
              startRecording={startRecording}
              handleStopRecording={handleStopRecording}
              editingTrackId={null}
              editingTrackTitle=""
              handleEditTrack={() => {}}
              handleSaveTrackTitle={() => {}}
              handleCancelEdit={() => {}}
              setEditingTrackTitle={() => {}}
              handleDeleteTrack={() => {}}
              handlePause={handlePause}
              albumId={album?.id}
              showTrackControls={false}
              playingSegmentIndex={playingSegmentIndex}
              handleDeleteSegment={handleDeleteSegment}
              remainingTime={remainingTime}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Track;
